<template>
  <div>
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Account Information
      </h3>
      <hr class="flex-1 border-primary mx-4" />
       <button  @click="updateContact"
        class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Details</span>
      </button>
    </div>
    <div class="flex flex-wrap -my-3 -mx-5">


      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <label class="uppercase font-semibold text-sm">DISTRIBUTOR</label>

        <ContactAutocomplete
            class="w-full"
            v-model="agent "
            :forceSelection="true"/>

      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <label class="uppercase font-semibold text-sm">ACCOUNT BALANCE</label>
        <input v-model="local_contact.account_balance" class="bge-input bge-input-spacing rounded" type="text" />
      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <label class="uppercase font-semibold text-sm">VTS No</label>
        <input v-model="local_contact.vts_number" class="bge-input bge-input-spacing rounded" type="text" />
      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <div class="flex w-full mt-5">
          <div class="w-1/2 ">
            <ToggleButton v-model="local_contact.is_customer" onLabel="CUSTOMER" offLabel="CUSTOMER" onIcon="pi pi-stop-circle" offIcon="pi pi-circle" style="width: 10em" />
          </div>
          <div class="w-1/2 ">
            <ToggleButton v-model="local_contact.is_distributor" onLabel="DISTRIBUTOR" offLabel="DISTRIBUTOR" onIcon="pi pi-stop-circle" offIcon="pi pi-circle" style="width: 10em" />
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <label class="uppercase font-semibold text-sm">PRICE BAND</label>
        <select v-model="local_contact.price_band" class="bge-input bge-input-spacing rounded">
          <option v-for="price_band in price_bands" :key="price_band.data_master_id" :value="price_band.value">
            {{ price_band.name }}
          </option>
        </select>

      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">

        <label class="uppercase font-semibold text-sm">CREDIT LIMIT</label>
        <select v-model="local_contact.credit_limit" class="bge-input bge-input-spacing rounded">
          <option v-for="credit_limit in credit_limits" :key="credit_limit.data_master_id" :value="credit_limit.value">
            {{ credit_limit.name }}
          </option>
        </select>


      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <label class="uppercase font-semibold text-sm">VAT No</label>
        <input v-model="local_contact.vat_number" class="bge-input bge-input-spacing rounded" type="text" />
      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <div class="flex w-full mt-5">

          <div class="w-1/2 ">
            <ToggleButton v-model="local_contact.is_supplier" onLabel="SUPPLIER" offLabel="SUPPLIER" onIcon="pi pi-stop-circle" offIcon="pi pi-circle" style="width: 10em" />
          </div>
          <div class="w-1/2">
            <ToggleButton
                v-model="local_contact.is_deactivated"
                onLabel="DEACTIVATE"
                offLabel="DEACTIVATE"
                onIcon="pi pi-stop-circle"
                offIcon="pi pi-circle"
                style="width: 10em"
            />
          </div>


        </div>
      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <label class="uppercase font-semibold text-sm">Customer Account No.</label>
        <input v-model="local_contact.account_reference" class="bge-input bge-input-spacing rounded" type="text" />
      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <label class="uppercase font-semibold text-sm">PAYMENT TERMS</label>
          <select v-model="local_contact.payment_terms" class="bge-input bge-input-spacing rounded">
            <option v-for="payment_term in payment_terms" :key="payment_term.data_master_id" :value="payment_term.value">
              {{ payment_term.name }}
            </option>
          </select>
      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">

      </div>

      <div class="flex flex-col w-full sm:w-1/3 lg:w-1/4 my-3 px-5">
        <div class="flex w-full mt-5">

          <div class="w-1/2">
            <ToggleButton v-model="local_contact.is_on_hold" onLabel="ON HOLD" offLabel="ON HOLD" onIcon="pi pi-stop-circle" offIcon="pi pi-circle" style="width: 10em" />
          </div>

          <div class="w-1/2 ">
            <ToggleButton v-model="local_contact.is_overdue" onLabel="OVERDUE" offLabel="OVERDUE" onIcon="pi pi-stop-circle" offIcon="pi pi-circle" style="width: 10em" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ContactAutocomplete from "@/components/AutoComplete/ContactAutoComplete.vue";
import notificationMixin from "@/mixins/notificationMixin";
const Spinner = () => import("@/components/Spinner.vue");

export default {
  name: "AccountsInformation",
  mixins: [notificationMixin],
  components: {
    ContactAutocomplete,
    Spinner,
  },
  props: {
    contact: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusy:false,
      local_contact: { ...this.contact },
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
      distributor:null,
      price_bands:null,
      credit_limits:null,
      payment_terms:null,
      agent:null,
    };
  },
  async mounted() {

    this.isBusy=true;
    await this.fetchDataMasterPriceBands();
    await this.fetchDataMasterCreditLimits();
    await this.fetchDataMasterPaymentTerms();
    this.isBusy=false;


    if(this.contact.agent_id && this.contact.agent_name){
      this.agent = { contact_id:this.contact.agent_id, name:this.contact.agent_name };
    }
  },
  methods: {

    async updateContact() {
      try {

        if(this.agent && this.agent.contact_id){
          this.local_contact.agent_id = this.agent.contact_id;
          this.local_contact.agent_name = this.agent.name;
        }
        await this.ContactService.updateContact(this.local_contact.contact_id, this.local_contact);
        debugger;
        this.notifySuccess("Contact updated successfully");
        this.$emit("refreshContact");
      } catch (error) {
        this.notifyError("Couldn't update contact");
        console.error(error);
      }
    },

    async fetchDataMasterPriceBands() {
      try {
        const response = await this.DatamasterService.getDataMasters('?noPaging=true&type=PriceBand&is_active=true&orderBy=order&order=1');
        this.price_bands = response.map(item => ({
          data_master_id: item.data_master_id,
          name: item.name,
          value: item.value || '', // Assuming 'value' is used in your model; adjust as needed
        }));
      } catch (error) {
        console.error('Error fetching data master price bands:', error);
      }
    },

    async fetchDataMasterCreditLimits() {
      try {
        const response = await this.DatamasterService.getDataMasters('?noPaging=true&type=CreditLimit&is_active=true&orderBy=order&order=1');
        this.credit_limits = response.map(item => ({
          data_master_id: item.data_master_id,
          name: item.name,
          value: item.value || '',
        }));
      } catch (error) {
        console.error('Error fetching data master credit limits:', error);
      }
    },

    async fetchDataMasterPaymentTerms() {
      try {
        const response = await this.DatamasterService.getDataMasters('?noPaging=true&type=PaymentTerms&is_active=true&orderBy=order&order=1');
        this.payment_terms = response.map(item => ({
          data_master_id: item.data_master_id,
          name: item.name,
          value: item.value || '',
        }));
      } catch (error) {
        console.error('Error fetching data master payment terms:', error);
      }
    },


  },
};
</script>
